export const us = {
    signIn: 'Sign In',
    username: 'Username',
    password: 'Password',
    tokenSignin: 'Sign in with token',
    nickname: 'Nickname',
    signUp: 'Sign Up',
    registrationFailed:'Registration Failed',
    signUpSuccessfully: 'You\'ve signed up successfully!',
    loginSuccessfully: 'You\'ve login successfully!',
    logoutSuccessfully: 'You\'ve logout successfully!',
    notEmpty: 'cannot be empty',
    request: 'want to add you as a friend',
    add: 'Add',
    delete: 'Delete',
    modify: 'Modify',
    confirm: 'Confirm to: ',
    addAFriend: 'Add friend',
    delAFriend: 'Delete friend',
    quit: 'Quit',
    groups: 'Groups',
    strangers: 'Strangers',
    chatrooms: 'Chat rooms',
    friends: 'Friends',
    block: 'Block',
    blacklist: 'Blacklist',
    invite: 'Invite',
    close: 'Close',
    message: 'Message',
    exceed: 'Please upload a file less than 10M.',
    invalidType: 'Invalid type',
    uploadFileFailed: 'An error occured when uploading a file',
    join: 'Join',
    agree: 'Agree',
    reject: 'Reject',
    send: 'Send',
    notin: 'You are not in the current chat room.',
    sendImageFailed: 'Failed to send image!',
    sendAudioFailed: 'Failed to send audio!',
    sendFileFailed: 'Failed to send file!',
    image: 'Image',
    audio: 'Audio',
    file: 'File',
    location: 'Location',
    video: 'Video',
    cmd: 'Command',
    download: 'Download',
    noaccount: 'New around here?',
    signupnow: 'Sign up now',
    haveaccount: 'Already have an account?',
    you: 'You',
    admin: 'admin',
    dismissed: 'had been dismissed',
    muted: 'had been muted',
    // subscribe
    refuse: 'Refused',
    subscribed: 'Subscribed',
    unsubscribed: 'Unsubscribed',
    read: 'read',
    unread: 'unread',
    // group
    createGroup: 'Create group',
    groupType: 'Group Type',
    private: 'Private',
    public: 'Pubilc',
    permission: 'Permission to join',
    approvalTrue: 'Approval',
    approvalFalse: 'Without Approval',
    next: 'Next',
    back: 'Back',
    create: 'Create',
    joinGroup: 'join group',
    groupInfo: 'Group Info',
    groupName: 'Group Name',
    groupNameWaring: 'Please input Group Name !',
    inviteToGroup: 'Invite to join group',
    groupRequest: 'Group Request',
    modifyGroupInfo: 'Modify group info',
    groupBlacklist: 'Black list of group',
    removeFromGroupBlackList: 'Remove from group blacklist',
    dissovleGroup: 'Dissolve Group',
    quitGroup: 'Quite group',
    members: 'Members',
    setAdmin: 'Set as admin',
    removeAdmin: 'Rmove admin',
    mute: 'Mute',
    groupBlockSingle: 'Block member to group blacklist',
    removeSingleGroupMember: 'Remove member from group',
    descriptionWaring: 'Please enter description !',
    description: 'description',
    // stranger
    addFriend: 'Add Friend',
    addFriendMessage: 'Application send out, please wait for approval',
    // search
    search: 'search',
    // system
    apply: 'apply to',
    offline: 'Offline',
    group: 'group',
    chatroom: 'chatroom',
    timeout: 'Loader timed out!',
    loadFailded: 'Error! Component failed to load',
    successfully: 'Successfully',
    failed: 'Failed',
    by: ' by ',
    sentFailed: 'sent failed',
    serverSideCloseWebsocketConnection: 'server-side close the websocket connection',
    webIMConnectionAuthError: 'WebIM connection auth error',
    offlineByMultiLogin: 'offline by multi login',
    noMoreMessage: 'no more messages',
    already: 'already',
    in: 'in',
    id: 'ID',
    notExist: 'Not exist',
    needApproval: 'Need approval',
    empty: 'Empty',
    back: 'Back',
    //webrtc
    requestVideo: ' is requesting for video...',
    requestVoice: ' is requesting for voice...',
    waiting: 'waiting ',
    waitingVideo: ' on video...',
    waitingVoice: ' on voice...',
    serverConfiguration: 'Server Configuration',
    saveSetting: 'Save Setting',
    getCaptcha: 'Get Captcha',
    captcha: 'Captcha',
    phoneNumber: 'Phone Number',
    imageVerification: 'Image Verification',
    findBackPassword: 'Retrieve the password',
    appKey: 'AppKey',
    imServerAddress: 'IM Server Address',
    port: 'Port',
    restServerAddress: 'REST Server Address',
    settingSuccess: 'Setting Success',
    againstFraudTips: 'This application is only used for function development and testing of Huanxin products. Please do not use it for illegal purposes. Any involved in transfer, remittance, naked chat, online love, online shopping refund, investment and financial management are all fraud, do not believe!',
    newPassword: 'new password',
    confirmPassword: 'confirm password',
    complete: 'Complete',
    myGroupNick: 'My nickname in group',
    editGroupNickname: 'Edit my group attributes',
    editNickTip: 'Please input the group name',
    cancel: 'Cancel',
    confirm: 'Confirm',
    editGroupNickSuccess: 'Edit success',
    editGroupNickFailed: 'Edit failed',
    youCanSendItLater: 'You can send it later~',
}
